/* Google Font Link */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 78px;
  background: #3b3b3a;
  padding: 6px 14px;
  z-index: 99;
  transition: all 0.5s ease;
}
.sidebar.open {
  width: 250px;
}
.sidebar .logo-details {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
}
.sidebar .logo-details .icon {
  opacity: 0;
  transition: all 0.5s ease;
}
.sidebar .logo-details .logo_name {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  opacity: 0;
  transition: all 0.5s ease;
}
.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name {
  opacity: 1;
}
.sidebar .logo-details #btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 22px;
  transition: all 0.4s ease;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
}
.sidebar.open .logo-details #btn {
  text-align: right;
}
.sidebar i {
  color: #fff;
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}
.sidebar .nav-list {
  margin-top: 20px;
  height: 100%;
  padding-left: 0rem;
}
.sidebar li {
  position: relative;
  margin: 8px 0;
  list-style: none;
}
.sidebar li .tooltip {
  position: absolute;
  top: -20px;
  left: calc(100% + 15px);
  z-index: 3;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: 0s;
}
.sidebar li:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  transform: translateY(-50%);
}
.sidebar.open li .tooltip {
  display: none;
}
.sidebar input {
  font-size: 15px;
  color: #fff;
  font-weight: 400;
  outline: none;
  height: 50px;
  width: 100%;
  width: 50px;
  border: none;
  border-radius: 12px;
  transition: all 0.5s ease;
  background: #fbbb00;
}
.sidebar.open input {
  padding: 0 20px 0 50px;
  width: 100%;
}
.sidebar .bx-search {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 22px;
  background: #fbbb00;
  color: #fff;
}
.sidebar .bx-search:hover {
  cursor: pointer;
}
.sidebar.open .bx-search:hover {
  background: #1d1b31;
  color: #fff;
}
.sidebar .bx-search:hover {
  background: #fff;
  color: #11101d;
}
.sidebar li a {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  background: #11101d;
}
.sidebar li a:hover {
  background: #fff;
}
.sidebar li a .links_name {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}
.sidebar.open li a .links_name {
  opacity: 1;
  pointer-events: auto;
}
.sidebar li a:hover .links_name,
.sidebar li a:hover i {
  transition: all 0.5s ease;
  color: #11101d;
}
.sidebar li i {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
}
.sidebar li.profile {
  position: fixed;
  height: 60px;
  width: 78px;
  left: 0;
  bottom: -8px;
  padding: 10px 14px;
  background: #fbbb00;
  transition: all 0.5s ease;
  overflow: hidden;
}
.sidebar.open li.profile {
  width: 250px;
}
.sidebar li .profile-details {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.sidebar li img {
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 10px;
}
.sidebar li.profile .name,
.sidebar li.profile .job {
  font-size: 15px;
  font-weight: 400;
  color: #3b3b3a;
  white-space: nowrap;
}
.sidebar li.profile .job {
  font-size: 12px;
}
.sidebar .profile #log_out {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: #fbbb00;
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 0px;
  transition: all 0.5s ease;
}
.sidebar.open .profile #log_out {
  width: 50px;
  background: none;
}
.sidebar .bx-log-out {
  color: #3b3b3a;
}
.home-section {
  position: relative;
  background-color: #f3f3f3;
  background-image: url("imboden-schnitt.png");
  background-size: 25%;
  background-repeat: no-repeat;
  background-position: bottom right;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
  padding: 30px;
}


.sidebar.open ~ .home-section {
  left: 250px;
  width: calc(100% - 250px);
}
.home-section .text {
  display: flex;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 18px;
}
@media (max-width: 420px) {
  .sidebar li .tooltip {
    display: none;
  }
}

.fc-toolbar-title {
  font-size: 15px !important;
}

.fc-timeline-slot-frame {
  justify-content: center !important;
}

.fc-timeline-slot-cushion.fc-scrollgrid-sync-inner {
  font-size: 15px !important;
  text-decoration: none;
  color: #3b3b3a;
}

.fc-event-main {
  color: #3b3b3a !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.5em !important;
}

.fc-button {
  font-size: 12px !important;
  padding: 5px 10px !important;
  height: auto !important;
  background-color: #3b3b3a !important;
}

.fc-datagrid-cell-main {
  font-size: 15px;
}

.speciale.modal-footer {
  justify-content: space-between;
}

.speciale.btn.btn-success {
  margin-left: 10px;
}

.fc-daygrid-day-number {
  text-decoration: none;
  color: #3b3b3a !important;
}

.fc-col-header-cell-cushion {
  text-decoration: none;
  color: #3b3b3a !important;
}

.fc-daygrid-day.fc-day-today {
  background-color: rgba(59, 59, 58, 0.1) !important;
}

.fc-list-day-text,
.fc-list-day-side-text {
  text-decoration: none;
  color: #3b3b3a !important;
}

.accordionFooter {
  display: flex;
  justify-content: space-between;
}

.col {
  background-color: #fff;
  margin: 15px 0px 15px 0px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 15px 30px;
  height: 100%;
}

.container {
  max-width: 100% !important;

  /*NEW*/
  /*display: flex;*/
  justify-content: center;
}

.accordion {
  margin-bottom: 10px;
}

.dashTitle {
  margin-bottom: 15px;
}

/* NEW */
/* Style for the input fields */
input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Placeholder text color */
input[type="text"]::placeholder,
input[type="password"]::placeholder {
  color: #888;
}

/* Style for the submit button */
button[type="submit"] {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 220px;
}

/* Hover effect for the button */
button[type="submit"]:hover {
  background-color: #0056b3;
  width: 220px;
}

.fc-toolbar-chunk {
  margin-bottom: 10px;
}

@media (max-width: 1180px) {
  .fc .fc-toolbar {
    flex-direction: column !important;
  }
  .home-section {
    padding: 0px;
  }
}

@media (max-width: 640px) {
  .fc .fc-toolbar {
    font-size: 0.95em;
  }
}

@media (max-width: 420px) {
  #calender {
    display: none;
  }
}

